class Widget {
    constructor(selector) {
        this.selector = selector;
        this.element = document.querySelector(selector);
    }
    clickCard(element) {
        element.querySelectorAll(".card").forEach(elem => {
            elem.addEventListener("click", e => {
                e.preventDefault();
                window.location = e.currentTarget.querySelector("a").href;
            });
        });
    }
    startScreen(element) {
        element.querySelectorAll(".card").forEach(elemn => {

            let device = elemn.querySelector(".device-image");
            let hardware = elemn.querySelector(".hardware-image");
            if (hardware) {
                let screen = elemn.querySelector(".start-image");
                let wrapper = elemn.querySelector(".device-image-wrapper");

                let scale = 1;

                if (device.dataset.imageSizeX > device.offsetWidth
                    && device.dataset.imageSizeY > device.offsetHeight) {
                    if (device.dataset.imageSizeX > device.dataset.imageSizeY) {
                        scale = device.offsetWidth / device.dataset.imageSizeX;
                    } else {
                        scale = device.offsetHeight / device.dataset.imageSizeY;
                    }

                } else if (device.dataset.imageSizeX > device.offsetWidth) {
                    scale = device.offsetWidth / device.dataset.imageSizeX;
                } else {
                    scale = device.offsetHeight / device.dataset.imageSizeY;
                }

                // if (scale>1) scale = 1;
                screen.style.left = Number(device.dataset.screenPositionX) + "px";
                screen.style.top = Number(device.dataset.screenPositionY) + "px";

                wrapper.style.transform = `scale(${scale})`;
                wrapper.style.left = ((Number(device.offsetWidth) / 2) - (Number(device.dataset.imageSizeX * scale) / 2)) + "px";
                wrapper.style.top = ((Number(device.offsetHeight) / 2) - (Number(device.dataset.imageSizeY * scale) / 2)) + "px";
                // hardware.style.transform = `scale(${scale})`;
                // 
            }
        });
    }
    initAccordion(item, closeOthers = false) {
        item.querySelectorAll(".accordion-item").forEach(element => {
            element.dataset.contentheight = element.querySelector(".content").offsetHeight;
            element.querySelector(".content").style.height = 0;
            element.querySelector(".title").addEventListener("click", e => {
                let parentNode = e.currentTarget.parentNode;
                let content = parentNode.querySelector(".content");

                parentNode.classList.toggle("active");

                if (parentNode.classList.contains("active")) {
                    let delay = 200 + (parentNode.dataset.contentheight / 2);
                    content.style.transitionDuration = delay + "ms";
                    content.style.height = parentNode.dataset.contentheight + "px";

                    if (closeOthers) {
                        setTimeout(() => {
                            parentNode.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }, delay);
                        item.querySelectorAll(".accordion-item").forEach(el => {
                            if (parentNode != el) {
                                el.querySelector(".content").style.transitionDuration = "200ms";
                                el.querySelector(".content").style.height = 0;
                                el.classList.remove("active");
                            }
                        });
                    }

                } else {
                    content.style.transitionDuration = "200ms";
                    content.style.height = 0;
                }
            });
        });
    }
}